(function(angular, $){
  'use strict';

  angular.module('department', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.department', {
            url: '/departamento/:departmentId',
            abstract: true,
            templateUrl: 'app/modules/department/main.tpl.html',
            resolve: {
              departamento: ['$q', '$stateParams', 'departamentos',
                function($q, $stateParams, departamentos){
                  var deferred = $q.defer();
                  var departamento = _.find(departamentos, {id: parseInt($stateParams.departmentId, 10)});
                  if(departamento){
                    deferred.resolve(departamento);
                  }else{
                    deferred.reject();
                  }
                  return deferred.promise;
                }
              ],
              traslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  var params = {
                    categoria: 'DIVISION_POLITICA',
                    // 977: Bogota
                    clase: ($stateParams.departmentId == 977) ? 'MUNICIPIO' : 'DEPARTAMENTO',
                    termino_id: $stateParams.departmentId
                  };
                  Traslapes.areas(params).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]
              /*departamentoTraslapes: ['$q', '$stateParams', 'Traslapes',
                function($q, $stateParams, Traslapes){
                  var deferred = $q.defer();
                  // 977: Bogota
                  var method = ($stateParams.departmentId == 977) ? 'byCity' : 'byDepartment';
                  Traslapes[method]($stateParams.departmentId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]*/
              // @TODO: borrar
              /*documents: ['$q', '$stateParams', 'Documentos',
                function($q, $stateParams, Documentos){
                  var deferred = $q.defer();
                  Documentos.byDepartment($stateParams.departmentId).then(function(data){
                    deferred.resolve(data.items);
                  }, function(response){
                    deferred.reject(response);
                  });
                  return deferred.promise;
                }
              ]*/
            },
            controller: 'Department.MainController'
          })
          .state('main.views.department.views', {
            url: '',
            views: {
              intro: {
                templateUrl: 'app/modules/department/intro.tpl.html',
                controller: 'Department.IntroController'
              },
              // @TODO: borrar
              /*timeline: {
                templateUrl: 'app/modules/department/timeline.tpl.html',
                controller: 'Department.TimelineController'
              },*/
              chart: {
                templateUrl: 'app/modules/department/chart.tpl.html',
                controller: 'Department.ChartController'
              },
              documents: {
                templateUrl: 'app/modules/department/areas.tpl.html',
                controller: 'Department.AreasController'
              }
            }
          });
      }
    ])

    .controller('Department.MainController', ['$scope',
      function($scope){
        // Valores iniciales
        $scope.common = {model: {}};
      }
    ])

    .controller('Department.IntroController', ['$scope', '$timeout', 'ServerRequest', 'MathService', 'Imagenes', 'backend', 'departamento', 'traslapes',
      function($scope, $timeout, ServerRequest, MathService, Imagenes, backend, departamento, traslapes){
        $scope.departamento = departamento;
        $scope.numberAreas = traslapes.length;

        $scope.areasChartsSR = new ServerRequest();
        $scope.imagesSR = new ServerRequest();
        $scope.mapFlag = false;

        // Calcula los valores de la grafica de geografica
        var geografica = {terrestre: 0, maritima: 0};
        angular.forEach(traslapes, function(item){
          geografica.terrestre += item.area.geografica.terrestre;
          geografica.maritima += item.area.geografica.maritima;
        });
        geografica.total = geografica.terrestre + geografica.maritima;
        geografica.porcentajes = {
          terrestre: MathService.round((geografica.terrestre / geografica.total) * 100),
          maritima: MathService.round((geografica.maritima / geografica.total) * 100)
        };
        $scope.geografica = geografica;

        // Graficas de areas
        $scope.areasChartsSR.start();
        $timeout(function(){
          $scope.areasChartsSR.success();
          $scope.chart1 = {
            id: 'chart1',
            values: [geografica.terrestre, geografica.maritima],
            colors: ['#fe9927', '#cd2d2e']
          };
        });

        // @TODO: deprecated
        // $timeout(function(){
        //   var params = [
        //     '',
        //     'pnn:runap2,pnn:departamentos',
        //     _.sprintf("INTERSECTS(shape, querySingle('pnn:departamentos', 'shape', 'codigo = %s'));INCLUDE", departamento.codigo)
        //   ];
        //   debug.info('visor params', params[0], params[1], params[2]);
        //   V.init(params[0], params[1], params[2]);
        // }, 100);

        const options = {
          scale: true,
          baseMap: false,
          toolbar: true,
          mapOptions: {
            // Para quitar control de zoom
            zoomControl: true,
            // Para deshabilitar zoom al hacer scroll con mouse controles de zoom y extension
            scrollWheelZoom: false,
            maxZoom: 16,
            minZoom: 5,
          },
          showButton: {
            layers: true,
            // addLayer: true,
            identify: false,
            // markers: true,
            // draw: true,
            // filter: true,
            // toolbox: true,
          },
          mapEvents: {
            popupOnClick: true,
          },
        };

        const layers = [
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Runap',
            visible: true,
            topic: 'Runap',
            order: 2,
            description: 'Capa de Runap',
            styles : 'pnn:limites_parques_etiqueta',
            options: {
              layers: 'pnn:runap2',
              query: `INTERSECTS(shape, querySingle('pnn:departamentos', 'shape', 'codigo = ${departamento.codigo}'))`,
              transparent: true,
              format: 'image/png',
            },
          },
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Departamentos',
            visible: true,
            topic: 'Político',
            order: 1,
            description: 'Capa de departamentos',
            options: {
              layers: 'pnn:departamentos',
              transparent: true,
              format: 'image/png',
            },
          },
        ];

        const visor = new Visor({
          container: 'map',
          options,
          layers,
        });

        // Dispara este evento cuando ha cargado todas las capas por defecto del visor
        visor.map.on('ready', () => {
          visor.map.fitBounds([
            [-4.227, -82.71],
            [13.393, -66.876],
          ]);

          visor.setSelectedLayer('Runap');
        });

        // Consulta las imagenes del carousel
        $scope.imagesSR.start();
        Imagenes.byDepartment(departamento.id).then(function(data){
          $scope.imagesSR.success();
          $scope.images = data.items;
        }, function(response){
          $scope.imagesSR.setErrorResponse(response);
        });
      }
    ])

    // @TODO: borrar
    /*.controller('Department.TimelineController', ['$scope', '$filter', '$timeout', 'documents',
      function($scope, $filter, $timeout, documents){
        // No puede haber mas de un elemento con la misma fecha para que muestre
        // bien la linea de tiempo
        var uniqueDocuments = _.uniq(documents, function(document){
          return document.fecha;
        });
        var timelineItems = _.map(uniqueDocuments, function(document){
          var date = new Date(document.fecha * 1000);
          date.setDate(date.getDate() + 1);
          var dateStr = $filter('date')(date, 'dd/MM/yyyy');
          return {
            date: dateStr,
            label: dateStr
          };
        });
        angular.forEach(timelineItems, function(item){
          item.documents = _.filter(documents, function(document){
            var date = new Date(document.fecha * 1000);
            date.setDate(date.getDate() + 1);
            var dateStr = $filter('date')(date, 'dd/MM/yyyy');
            return (dateStr === item.date);
          });
        });
        $scope.timelineItems = timelineItems;

        if(timelineItems.length){
          $timeout(function(){
            var timelines = $('.cd-horizontal-timeline');
            (timelines.length > 0) && initTimeline(timelines, 80, 240);
          });
        }
      }
    ])*/

    .controller('Department.ChartController', ['$scope', '$timeout', 'MathService', 'StringService', 'ServerRequest', /*'Charts',*/ 'traslapes', /*'departamentoTraslapes',*/
      function($scope, $timeout, MathService, StringService, ServerRequest, /*Charts,*/ traslapes/*, departamentoTraslapes*/){
        $scope.chartSR = new ServerRequest();

        debug.info('traslapes', traslapes);
        //debug.info('departamentoTraslapes', departamentoTraslapes);

        // Obtiene los tipos de graficas
        /*var categories = _.map(departamentoTraslapes, function(item){
          var label;
          if(item.categoria === 'SISTEMA_NATURAL'){
            if(item.clase === 'BIOGEOGRAFICO'){
              label = 'Biogeográficos';
            }else if(item.clase === 'BIOMA'){
              label = 'Biomas';
            }else if(item.clase === 'ECOSISTEMA'){
              label = 'Ecosistemas';
            }
          }else if(item.categoria === 'CLASIFICACION'){
            if(item.clase === 'UICN'){
              label = 'Categorías UICN';
            }
          }else if(item.categoria === 'DIVISION_POLITICA'){
            if(item.clase === 'DEPARTAMENTO'){
              label = 'Departamentos';
            }else if(item.clase === 'MUNICIPIO'){
              label = 'Municipios';
            }
          }else if(item.categoria === 'OBJETIVO'){
            if(item.clase === 'GENERAL'){
              label = 'Objetivos generales';
            }else if(item.clase === 'ESPECIFICO'){
              label = 'Objetivos específicos';
            }
          }else if(item.categoria === 'ORGANIZACION'){
            if(item.clase === 'CAR'){
              label = 'CARs';
            }else{
              label = 'Organizaciones';
            }
          }else if(item.categoria === 'TRASLAPE'){
            if(item.clase === 'AREA'){
              label = 'Áreas protegidas';
            }
          }else if(item.categoria === 'USO'){
            label = 'Usos';
          }
          if(label){
            return {
              value: _.sprintf('%s.%s', item.categoria, item.clase),
              label: label
            };
          }
          return null;
        });
        categories = _.filter(categories, function(item){
          return !!item;
        });
        categories = _.uniq(categories, function(item){
          return item.value;
        });*/
        var categories = [];
        categories.push({
          value: 'TRASLAPE.AREA',
          label: 'Áreas protegidas'
        });
        categories = _.sortBy(categories, function(item){
          return StringService.normalize(item.label);
        });
        if(categories.length){
          // Selecciona el boton inicial
          $scope.common.model.chart = categories[0].value;
        }
        $scope.charts = categories;

        var generateChart = function(chart){
          var chartOptions = {
            title: {
              text: chart.label
            },
            subtitle: {
              text: '(hectáreas)'
            },
            colorAxis: {
              minColor: '#FFFFFF',
              maxColor: '#7CB5EC'
            },
            tooltip: {
              formatter: function(){
                var key;
                if(this.key.length > 30){
                  key = _.truncate(this.key, 30);
                }else{
                  key = this.key;
                }
                return _.sprintf('<strong>%s:</strong> %s ha', key, this.point.value);
              }
            },
            series: [{
              type: 'treemap',
              layoutAlgorithm: 'squarified',
              data: []
            }]
          };

          debug.info('generateChart', chart);
          var terminos;
          if(chart.value === 'TRASLAPE.AREA'){
            terminos = traslapes;
            angular.forEach(terminos, function(termino){
              var value = 0;
              angular.forEach(termino.area.geografica, function(item){
                if(item){
                  value += parseFloat(item);
                }
              });
              var color = MathService.round(value);
              value = parseFloat(MathService.toPrecision(value, 4));
              chartOptions.series[0].data.push({
                name: termino.contexto_termino,
                value: value,
                colorValue: color
              });
            });
          }else{
            terminos = _.filter(departamentoTraslapes, function(termino){
              return (_.sprintf('%s.%s', termino.categoria, termino.clase) === chart.value);
            });
            angular.forEach(terminos, function(termino){
              var value = 0;
              angular.forEach(termino.area.geografica, function(item){
                if(item){
                  value += parseFloat(item);
                }
              });
              var color = MathService.round(value);
              value = parseFloat(MathService.toPrecision(value, 4));
              chartOptions.series[0].data.push({
                name: termino.termino,
                value: value,
                colorValue: color
              });
            });
          }
          debug.info('terminos', terminos, 'serie', chartOptions.series[0].data);

          $scope.chartOptions = chartOptions;
        };

        $scope.changeChart = function(chart){
          // Consulta las opciones del chart
          /*$scope.chartSR.start();
          Charts.get(chart).then(function(data){
            $scope.chartSR.success();
            $scope.chartOptions = data;
          }, function(response){
            $scope.chartSR.setErrorResponse(response);
          });*/

          $scope.chartSR.start();
          $timeout(function(){
            var chartItem = _.find($scope.charts, {value: chart});
            generateChart(chartItem);
            $scope.chartSR.success();
          });
        };

        if($scope.charts.length){
          $scope.changeChart($scope.charts[0].value);
        }
      }
    ])

    .controller('Department.AreasController', ['$scope', 'MathService', 'StringService', 'departamento', 'traslapes',
      function($scope, MathService, StringService, departamento, traslapes){
        $scope.departamento = departamento;
        $scope.numberAreas = traslapes.length;

        traslapes = _.sortBy(angular.copy(traslapes), function(item){
          return StringService.normalize(item.contexto_termino);
        });
        $scope.traslapes = _.map(traslapes, function(item){
          var total = item.area.geografica.terrestre + item.area.geografica.maritima;
          item.area.geografica.total = parseFloat(MathService.toPrecision(total, 4));
          return item;
        });
      }
    ]);
}(window.angular, window.$));
