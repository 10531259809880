(function(angular){
  'use strict';

  angular.module('runap.data', [])

    .config(['CacheFactoryProvider', function(CacheFactoryProvider){
      // Opciones de defecto de cache
      angular.extend(CacheFactoryProvider.defaults, {
        maxAge: 3600000,  // Items added to this cache expire after 1 hour.
        cacheFlushInterval: 7200000,  // This cache will clear itself every two hour.
        deleteOnExpire: 'aggressive',  // Items will be deleted from this cache right when they expire.
        storageMode: 'sessionStorage'  // none, localStorage, sessionStorage
      });
    }])

    .run(['CacheFactory', function(CacheFactory){
      // Configuraciones particulares de los caches
      CacheFactory('user', {disabled: false});
      CacheFactory('menu', {
        disabled: false,
        maxAge: 24 * 3600 * 1000,  // 24 horas
        cacheFlushInterval: 28 * 3600 * 1000  // 28 horas
      });
      CacheFactory('runap', {
        disabled: false,
        maxAge: 24 * 3600 * 1000,  // 24 horas
        cacheFlushInterval: 28 * 3600 * 1000  // 28 horas
      });
      CacheFactory('departamentos', {disabled: false});
      CacheFactory('organizaciones', {disabled: false});
      CacheFactory('terminos', {disabled: false});
      CacheFactory('imagenes', {disabled: false});
      CacheFactory('areas', {disabled: false});
      CacheFactory('traslapes', {disabled: false});
      CacheFactory('documentos', {disabled: false});
      CacheFactory('shapes', {disabled: false});
      CacheFactory('cifras', {disabled: false});
    }])

    .service('Cache', ['CacheFactory', function(CacheFactory){
      this.remove = function(cacheFactory){
        var cache = CacheFactory.get(cacheFactory);
        cache.removeAll();
      };

      this.removeUserCaches = function(){
        // @TODO: definir los caches a borrar
        var userCaches = ['notifications', 'users', 'schemasAdmin', 'schemas',
          'schemaPropertiesAdmin', 'schemaProperties', 'trees', 'nodeLabels',
          'batches', 'dataMaps', 'dataFlows', 'datasetsAdmin', 'datasets',
          'datasetTables', 'indicatorsAdmin', 'indicators', 'indicatorVersions',
          'dataBundles', 'filtersOptions', 'chartData'];
        angular.forEach(userCaches, function(userCache){
          this.remove(userCache);
        }, this);
      };
    }])

    .factory('User', ['$q', '$http', 'backend', 'runapv1', 'sinap',
      function($q, $http, backend, runapv1, sinap){
        return {
          login: function(login){
            var deferred = $q.defer();

            var url = _.sprintf('%s/default/index/login', runapv1);
            $http.post(url, login, {
              responseType: 'json'
            }).then(function(response){
              if(response.data && response.data.sid && response.data.redirect){
                deferred.resolve(response.data);
              }else{
                deferred.reject(response);
              }
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          signUp: function(model){
            var deferred = $q.defer();

            const url = _.sprintf('%s/autenticacion/registrar', sinap);
            $http.post(url, model, {
              responseType: 'json'
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          restorePassword: function(model){
            var deferred = $q.defer();

            var url = _.sprintf('%s/default/index/restorepassword', runapv1);
            $http.post(url, model, {
              responseType: 'json'
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Departamentos', ['$q', '$http', 'CacheFactory', 'backend',
      function($q, $http, CacheFactory, backend){
        return {
          query: function(params){
            var deferred = $q.defer();

            params = _.defaults(params || {}, {});

            $http.get('/json/departamentos/all.json', {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('departamentos')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          areas: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/departamentos/areas', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('departamentos')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Cifras', ['$q', '$http', 'CacheFactory', 'StringService', 'backend',
      function($q, $http, CacheFactory, StringService, backend){
        return {
          get: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/cifras', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('cifras')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Organizaciones', ['$q', '$http', 'CacheFactory', 'StringService', 'backend', 'sinap',
      function($q, $http, CacheFactory, StringService, backend, sinap){
        return {
          get: function(organizacionId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/organizaciones/%s', backend, organizacionId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('organizaciones')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          query: function(params){
            var deferred = $q.defer();

            params = _.defaults(params || {}, {orden: 'abreviatura ASC'});

            var url = _.sprintf('%s/organizaciones', backend);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('organizaciones')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          areas: function(params = null){
            let deferred = $q.defer();

            let url = _.sprintf('%s/organizaciones/areas', backend);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('organizaciones')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          conteoAreasDeclaradas: function(organizacionId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/organizaciones/%s/numero_areas_declaradas', backend, organizacionId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('organizaciones')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          conteoAreasDeclaradasRnsc: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/organizaciones/numero_rnsc_declaradas', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('organizaciones')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          areasIds: function(organizationId, params = null){
            let deferred = $q.defer();

            let url = _.sprintf('%s/areas/organizacion/%s', sinap, organizationId);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('organizaciones')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Terminos', ['$q', '$http', 'CacheFactory', 'StringService', 'backend',
      function($q, $http, CacheFactory, StringService, backend){
        return {
          query: function(params, sort){
            var deferred = $q.defer();

            var url = _.sprintf('%s/terminos', backend);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('terminos')
            }).then(function(response){
              // Ordenamiento en el cliente
              if(sort){
                if(response.data && angular.isArray(response.data.items)){
                  // @TODO: implementar con varios criterios de ordenamiento
                  /*if(!angular.isArray(sort)){
                    sort = [sort];
                  }*/
                  response.data.items = _.sortBy(response.data.items, function(item){
                    var text = StringService.normalize(item[sort]);
                    return text.toLowerCase();
                  });
                }
              }

              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          categoriasUICN: function(params, sort){
            var deferred = $q.defer();

            var url = _.sprintf('%s/terminos/categorias_uicn', backend);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('terminos')
            }).then(function(response){
              // Ordenamiento en el cliente
              if(sort){
                if(response.data && angular.isArray(response.data.items)){
                  // @TODO: implementar con varios criterios de ordenamiento
                  /*if(!angular.isArray(sort)){
                   sort = [sort];
                   }*/
                  response.data.items = _.sortBy(response.data.items, function(item){
                    var text = StringService.normalize(item[sort]);
                    return text.toLowerCase();
                  });
                }
              }

              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Imagenes', ['$q', '$http', 'CacheFactory', 'backend',
      function($q, $http, CacheFactory, backend){
        return {
          all: function(params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/imagenes', backend);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json'
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byArea: function(areaId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/%s/imagenes', backend, areaId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('imagenes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byDepartment: function(departmentId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/departamentos/%s/imagenes', backend, departmentId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('imagenes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byOrganization: function(organizationId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/organizaciones/%s/imagenes', backend, organizationId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('imagenes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byCategory: function(categoryId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/categorias/%s/imagenes', backend, categoryId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('imagenes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Areas', ['$q', '$http', 'CacheFactory', 'backend',
      function($q, $http, CacheFactory, backend){
        return {
          get: function(areaId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/%s', backend, areaId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('areas')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          conteo: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/conteo', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('areas')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          // @deprecated
          // goal: function(){
          //   var deferred = $q.defer();
          //
          //   var url = _.sprintf('%s/areas_protegidas/meta', backend);
          //   $http.get(url, {
          //     responseType: 'json',
          //     cache: CacheFactory.get('areas')
          //   }).then(function(response){
          //     deferred.resolve(response.data);
          //   }, function(response){
          //     deferred.reject(response);
          //   });
          //
          //   return deferred.promise;
          // },
          areas: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/areas', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('areas')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          areasActoByYear: function(organizacionId = null){
            var deferred = $q.defer();

            const params = {};
            if (organizacionId) {
              params.organizacionId = organizacionId;
            }

            var url = _.sprintf('%s/areas_protegidas/acto_administrativo/anios', backend);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('areas')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          areasActoBySinap: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/acto_administrativo/sinap', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('areas')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          areasActoByUicn: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/acto_administrativo/uicn', backend);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('areas')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Traslapes', ['$q', '$http', 'CacheFactory', 'backend',
      function($q, $http, CacheFactory, backend){
        return {
          areas: function(params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/contextos/areas', backend);
            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('traslapes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byArea: function(areaId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/contextos/area/%s', backend, areaId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('traslapes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byDepartment: function(departmentId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/contextos/departamento/%s', backend, departmentId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('traslapes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byCity: function(cityId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/contextos/municipio/%s', backend, cityId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('traslapes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byOrganization: function(organizationId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/contextos/organizacion/%s', backend, organizationId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('traslapes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byCategory: function(categoryId){
            var deferred = $q.defer();

            var url = _.sprintf('%s/contextos/categoria/%s', backend, categoryId);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('traslapes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Planes', ['$q', '$http', 'CacheFactory', 'backend',
      function($q, $http, CacheFactory, backend){
        return {
          byArea: function(areaId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/%s/planes', backend, areaId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('planes')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])
    .factory('Documentos', ['$q', '$http', 'CacheFactory', 'backend',
      function($q, $http, CacheFactory, backend){
        return {
          byArea: function(areaId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/areas_protegidas/%s/documentos', backend, areaId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('documentos')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byDepartment: function(departmentId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/departamentos/%s/documentos', backend, departmentId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('documentos')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byOrganization: function(organizationId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/organizaciones/%s/documentos', backend, organizationId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('documentos')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          },
          byCategory: function(categoryId, params){
            var deferred = $q.defer();

            var url = _.sprintf('%s/categorias/%s/documentos', backend, categoryId);
            params = _.defaults(params || {}, {});

            $http.get(url, {
              params: params,
              responseType: 'json',
              cache: CacheFactory.get('documentos')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Contact', ['$q', '$http', 'runapv1',
      function($q, $http, runapv1){
        return {
          send: function(contact){
            var deferred = $q.defer();

            var url = _.sprintf('%s/default/index/contact', runapv1);
            $http.post(url, contact, {
              responseType: 'json'
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Charts', ['$q', '$http', function($q, $http){
      return {
        get: function(chartId){
          var deferred = $q.defer();

          var url = _.sprintf('/json/charts/%s.json', chartId);
          $http.get(url, {
            responseType: 'json'
          }).then(function(response){
            deferred.resolve(response.data);
          }, function(response){
            deferred.reject(response);
          });

          return deferred.promise;
        }
      };
    }])

    .factory('Dominios', ['$q', '$http', 'CacheFactory', 'sinap',
      function($q, $http, CacheFactory, sinap){
        return {
          fechaActualizacionShape: function(){
            var deferred = $q.defer();

            var url = _.sprintf('%s/dominios/2350', sinap);
            $http.get(url, {
              responseType: 'json',
              cache: CacheFactory.get('fechaActualizacionShape')
            }).then(function(response){
              deferred.resolve(response.data);
            }, function(response){
              deferred.reject(response);
            });

            return deferred.promise;
          }
        };
      }
    ])

    .factory('Tests', ['$q', '$http',
      function($q, $http){
        return {
          test1: function(){
            var deferred = $q.defer();

            // {categoria: 'Pacífico', value1: 100, value2: 90},
            // {categoria: 'Orinoquía', planeado: 240, ejecutado: 30},
            // {categoria: 'Caribe', planeado: 125, ejecutado: 53},
            // {categoria: 'Andes Occidentales', planeado: 168, ejecutado: 90},
            // {categoria: 'Andes Nororientales', planeado: 190, ejecutado: 45},
            // {categoria: 'Amazonas', planeado: 89, ejecutado: 33}

            deferred.resolve({
              series: ['__category__', 'Planeado', 'Ejecutado'],
              data: [
                ['Pacífico', 100, 90],
                ['Orinoquía', 240, 30],
                ['Caribe', 125, null],
                ['Andes Occidentales', 168, 90],
                ['Andes Nororientales', 190, 45],
                ['Amazonas', 89, 33]
              ]
            });

            return deferred.promise;
          }
        };
      }
    ]);
}(window.angular));
