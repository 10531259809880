(function(angular){
  'use strict';

  angular.module('summary', [])

    .config(['$stateProvider',
      function($stateProvider){
        $stateProvider
          .state('main.views.summary', {
            url: '/cifras',
            templateUrl: 'app/modules/summary/summary.tpl.html',
            controller: 'Summary.MainController'
          });
      }
    ])

    .controller('Summary.MainController', ['$scope', '$timeout', 'ServerRequest', 'MathService', 'Areas', 'Departamentos', 'Organizaciones', 'Tests', 'Cifras', 'fechaActualizacionShape',
      function($scope, $timeout, ServerRequest, MathService, Areas, Departamentos, Organizaciones, Tests, Cifras, fechaActualizacionShape){
        // Fecha actualizacion informacion
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        $scope.yesterday = yesterday;

        // Fecha actualizacion shapefile consolidado
        $scope.fechaActualizacionShape = fechaActualizacionShape;

        Areas.conteo().then(function(data){
          $scope.numberAreas = data.numero_areas;
          $scope.numberReservations = data.numero_reservas;
        }, function(response){
          // @TODO: manejar el error
        });

        Cifras.get().then(function(data){
          $scope.totalHa = data.totalHa;
          $scope.totalProgreso = data.totalProgreso;
          // $scope.porcentajeTerrestre = data.porcentajeTerrestre;
          // $scope.porcentajeMaritimo = data.porcentajeMaritimo;
          $scope.totalMaritimoHa = data.totalMaritimoHa;
          $scope.totalTerrestreHa = data.totalTerrestreHa;

          // @deprecated
          // Areas.goal().then(function(data){
          //   var progress = data.progreso * 100;
          //   var porcentage = (progress / 2500000) * 100;
          //   $scope.progress = MathService.round(2500000 - progress);
          //   $scope.porcentage = MathService.round(porcentage);
          // }, function(response){
          //   // @TODO: manejar el error
          // });
          // $scope.total = $scope.totalHa;
          // var progress = $scope.totalProgreso;

          // $scope.progress = MathService.round(progress);
          // $scope.porcentage = MathService.round((progress / $scope.total) * 100);
        });

        // @TODO: deprecated
        // $timeout(function(){
        //   //var url = _.sprintf('%s/areas_protegidas/%s/geojson', backend, area.id);
        //   //V.init(url, 'pnn:runap2,pnn:departamentos,pnn:municipios');
        //   //V.init('', 'pnn:runap2,pnn:departamentos,pnn:municipios');
        //
        //   var params = [
        //     '',
        //     'pnn:runap2,pnn:departamentos',
        //     ''
        //   ];
        //   debug.info('visor params', params[0], params[1], params[2]);
        //   V.init(params[0], params[1], params[2]);
        // }, 100);

        const options = {
          scale: true,
          baseMap: false,
          toolbar: true,
          mapOptions: {
            // Para quitar control de zoom
            zoomControl: true,
            // Para deshabilitar zoom al hacer scroll con mouse controles de zoom y extension
            scrollWheelZoom: false,
            maxZoom: 16,
            minZoom: 5,
          },
          showButton: {
            layers: true,
            // addLayer: false,
            identify: false,
            // markers: false,
            // draw: false,
            // filter: false,
            // toolbox: false,
          },
          mapEvents: {
            popupOnClick: true,
          },
        };

        const layers = [
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Runap',
            visible: true,
            topic: 'Runap',
            order: 2,
            description: 'Capa de Runap',
            styles : 'pnn:limites_parques_etiqueta',
            options: {
              layers: 'pnn:runap2',
              transparent: true,
              format: 'image/png',
            },
          },
          {
            url: 'https://mapas.parquesnacionales.gov.co/services/pnn/wms',
            type: 'wms',
            name: 'Departamentos',
            visible: true,
            topic: 'Político',
            order: 1,
            description: 'Capa de departamentos',
            options: {
              layers: 'pnn:departamentos',
              transparent: true,
              format: 'image/png',
            },
          },
        ];

        const visor = new Visor({
          container: 'map',
          options,
          layers,
        });

        // Dispara este evento cuando ha cargado todas las capas por defecto del visor
        visor.map.on('ready', () => {
          visor.map.fitBounds([
            [-4.227, -82.71],
            [13.393, -66.876],
          ]);

          visor.setSelectedLayer('Runap');
        });

        $scope.areasChartsSR = new ServerRequest();

        $scope.areasChartsSR.start();
        Areas.areas().then(function(data){
          $scope.areasChartsSR.success();

          // Calcula los valores de la grafica de geografica
          var geografica = {
            terrestre: data.geografica.terrestre,
            maritima: data.geografica.maritima
          };
          geografica.total = geografica.terrestre + geografica.maritima;
          geografica.porcentajes = {
            terrestre: MathService.round((geografica.terrestre / geografica.total) * 100),
            maritima: MathService.round((geografica.maritima / geografica.total) * 100)
          };
          $scope.geografica = geografica;

          // Calcula los valores de la grafica de resolucion
          var acto = {
            terrestre: data.acto.terrestre,
            maritima: data.acto.maritima
          };
          acto.total = acto.terrestre + acto.maritima;
          acto.porcentajes = {
            terrestre: MathService.round((acto.terrestre / acto.total) * 100),
            maritima: MathService.round((acto.maritima / acto.total) * 100)
          };
          $scope.acto = acto;

          // Calcula los valores de la grafica de geografica vs resolucion
          var geograficaActo = {
            geografica: geografica.total,
            acto: acto.total
          };
          geograficaActo.total = geograficaActo.geografica + geograficaActo.acto;
          geograficaActo.porcentajes = {
            geografica: MathService.round((geograficaActo.geografica / geograficaActo.total) * 100),
            acto: MathService.round((geograficaActo.acto / geograficaActo.total) * 100)
          };
          $scope.geograficaActo = geograficaActo;

          $scope.areasChart1 = {
            id: 'chart1',
            values: [geografica.terrestre, geografica.maritima],
            colors: ['#fe9927', '#cd2d2e']
          };
          $scope.areasChart2 = {
            id: 'chart2',
            values: [acto.terrestre, acto.maritima],
            colors: ['#6ca754', '#72a9db']
          };
          $scope.areasChart3 = {
            id: 'chart3',
            values: [geograficaActo.geografica, geograficaActo.acto],
            colors: ['#e66101', '#5e3c99']
          };
        }, function(response){
          $scope.areasChartsSR.setErrorResponse(response);
        });

        $scope.departmentsSR = new ServerRequest();

        $scope.departmentsSR.start();
        Departamentos.areas().then(function(data){
          $scope.departmentsSR.success();

          data = _.map(data, function(item){
            return [item.nombre, MathService.round(item.km * 100)];
          });
          $scope.departmentsChartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Áreas protegidas por departamentos'
            },
            subtitle: {
              text: '(Calculado en SIG)'
            },
            xAxis: {
              type: 'category',
              crosshair: true,
              labels: {
                autoRotation: [-40, -50, -60, -70]
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Hectáreas (ha)'
              }
            },
            legend: {
              enabled: false
            },
            tooltip: {
              shared: true,
              headerFormat: '<strong>{point.key}:</strong>',
              pointFormat: ' {point.y}',
              valueSuffix: ' ha'
              /*formatter: function(){
                return _.sprintf('<strong>%s:</strong> %s ha', this.key, this.y);
              }*/
            },
            series: [{
              data: data,
              color: '#e26700',
            }]
          };
        }, function(response){
          $scope.departmentsSR.setErrorResponse(response);
        });

        $scope.organizationsSR = new ServerRequest();

        $scope.organizationsSR.start();
        Organizaciones.areas().then(function(data){
          $scope.organizationsSR.success();

          data = _.map(data, function(item){
            return [item.nombre, MathService.round(item.km * 100)];
          });
          $scope.organizationsChartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Áreas protegidas en jurisdicción de las corporaciones autonómas regionales (CAR\'s/CDS\'s)'
            },
            subtitle: {
              text: '(Calculado en SIG)'
            },
            xAxis: {
              type: 'category',
              crosshair: true,
              labels: {
                autoRotation: [-40, -50, -60, -70]
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Hectáreas (ha)'
              }
            },
            legend: {
              enabled: false
            },
            tooltip: {
              shared: true,
              headerFormat: '<strong>{point.key}:</strong>',
              pointFormat: ' {point.y}',
              valueSuffix: ' ha'
              /*formatter: function(){
                return _.sprintf('<strong>%s:</strong> %s ha', this.key, this.y);
              }*/
            },
            series: [{
              data: data,
              color: '#e26700',
            }]
          };
        }, function(response){
          $scope.organizationsSR.setErrorResponse(response);
        });

        $scope.yearsSR = new ServerRequest();

        $scope.yearsSR.start();
        Areas.areasActoByYear().then(function(data){
          $scope.yearsSR.success();

          var categories = _.map(data, 'anio');
          var seaSeries = _.map(data, 'km_maritimos');
          seaSeries = _.map(seaSeries, function(item){
            return MathService.round(item * 100);
          });
          var landSeries = _.map(data, 'km_terrestres');
          landSeries = _.map(landSeries, function(item){
            return MathService.round(item * 100);
          });
          var acumSeries = _.map(data, function(item){
            if(item.km_maritimos === null){
              item.km_maritimos = 0;
            }
            if(item.km_terrestres === null){
              item.km_terrestres = 0;
            }
            return (parseFloat(item.km_maritimos) + parseFloat(item.km_terrestres)) * 100;
          });
          var i;
          for(i = 1; i < acumSeries.length; i++){
            acumSeries[i] = MathService.round(acumSeries[i - 1] + acumSeries[i]);
          }

          $scope.yearsChartOptions = {
            chart: {
              zoomType: 'xy'
            },
            title: {
              text: 'Serie de tiempo de las áreas protegidas inscritas a la fecha'
            },
            subtitle: {
              text: '(Calculado por acto administrativo)'
            },
            plotOptions: {
              column: {
                stacking: 'normal'
              },
              spline: {
                lineWidth: 4,
                states: {
                  hover: {
                    lineWidth: 5
                  }
                },
                marker: {
                  enabled: false
                }
              }
            },
            xAxis: [{
              categories: categories,
              crosshair: true,
              labels: {
                autoRotation: [-40]
              }
            }],
            yAxis: [
              {  // Primary yAxis
                title: {
                  text: 'Marítimo y terrestre (ha)',
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                labels: {
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                min: 0
              },
              {  // Secondary yAxis
                title: {
                  text: 'Acumulado (ha)',
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                labels: {
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                min: 0,
                opposite: true
              }
            ],
            tooltip: {
              shared: true
            },
            series: [
              {
                name: 'Marítimo',
                type: 'column',
                data: seaSeries,
                tooltip: {
                  valueSuffix: ' ha'
                },
                color: Highcharts.getOptions().colors[0]
              },
              {
                name: 'Terrestre',
                type: 'column',
                data: landSeries,
                tooltip: {
                  valueSuffix: ' ha'
                },
                color: '#e26700'
              },
              {
                name: 'Acumulado',
                type: 'spline',
                yAxis: 1,
                data: acumSeries,
                tooltip: {
                  valueSuffix: ' ha'
                },
                color: Highcharts.getOptions().colors[7]
                /*marker: {
                  enabled: false
                }*/
              }
            ]
          };
        }, function(response){
          $scope.yearsSR.setErrorResponse(response);
        });

        $scope.sinapChartsSR = new ServerRequest();

        $scope.sinapChartsSR.start();
        Areas.areasActoBySinap().then(function(data){
          $scope.sinapChartsSR.success();

          const newData = [];
          let previous;
          let count = 0;
          let total = 0;
          data.forEach((item) => {
            if(!previous){
              previous = item.ambito;
            }else if(item.ambito != previous){
              newData.push({
                ambito: `Subtotal ${previous}`,
                subtotal: count
              });
              previous = item.ambito;
              count = 0;
            }
            count += item.conteo;
            total += item.conteo;
            newData.push(item);
          });
          newData.push({
            ambito: `Subtotal ${data[data.length - 1].ambito}`,
            subtotal: count
          });
          $scope.data = newData;
          $scope.total = total;

          let categories = _.map(data, 'categoria');

          let actoSeaSeries = _.map(data, 'acto_km_maritimos');
          actoSeaSeries = _.map(actoSeaSeries, function(item){
            return MathService.round(item);
          });

          let actoLandSeries = _.map(data, 'acto_km_terrestres');
          actoLandSeries = _.map(actoLandSeries, function(item){
            return MathService.round(item);
          });

          let sigSeaSeries = _.map(data, 'sig_km_maritimos');
          sigSeaSeries = _.map(sigSeaSeries, function(item){
            return MathService.round(item);
          });

          let sigLandSeries = _.map(data, 'sig_km_terrestres');
          sigLandSeries = _.map(sigLandSeries, function(item){
            return MathService.round(item);
          });

          $scope.sinapChartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Áreas protegidas por categorías SINAP'
            },
            plotOptions: {
              column: {
                stacking: 'normal'
              }
            },
            xAxis: {
              categories: categories,
              crosshair: true,
              labels: {
                autoRotation: [-40]
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Hectáreas (ha)'
              }
            },
            tooltip: {
              shared: true,
              valueSuffix: ' ha'
              //pointFormat: 'Population in 2008: <b>{point.y:.1f} millions</b>'
            },
            series: [
              {
                name: 'Marítimo - acto',
                type: 'column',
                stack: 'acto',
                data: actoSeaSeries,
                color: '#abdda4'
              },
              {
                name: 'Terrestre - acto',
                type: 'column',
                stack: 'acto',
                data: actoLandSeries,
                color: '#fdae61'
              },
              {
                name: 'Marítimo - sig',
                type: 'column',
                stack: 'sig',
                data: sigSeaSeries,
                color: '#2b83ba'
              },
              {
                name: 'Terrestre - sig',
                type: 'column',
                stack: 'sig',
                data: sigLandSeries,
                color: '#d7191c'
              }
            ]
          };
        }, function(response){
          $scope.sinapChartsSR.setErrorResponse(response);
        });

        $scope.uicnChartsSR = new ServerRequest();

        $scope.uicnChartsSR.start();
        Areas.areasActoByUicn().then(function(data){
          $scope.uicnChartsSR.success();

          let categories = _.map(data, 'categoria');

          let actoSeaSeries = _.map(data, 'haMaritimos');
          actoSeaSeries = _.map(actoSeaSeries, function(item){
            return MathService.round(item);
          });

          let actoLandSeries = _.map(data, 'haTerrestres');
          actoLandSeries = _.map(actoLandSeries, function(item){
            return MathService.round(item);
          });

          let sigSeaSeries = _.map(data, 'haMaritimosSig');
          sigSeaSeries = _.map(sigSeaSeries, function(item){
            return MathService.round(item);
          });

          let sigLandSeries = _.map(data, 'haTerrestresSig');
          sigLandSeries = _.map(sigLandSeries, function(item){
            return MathService.round(item);
          });

          $scope.uicnChartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Áreas protegidas por categorías UICN'
            },
            plotOptions: {
              column: {
                stacking: 'normal'
              }
            },
            xAxis: {
              categories: categories,
              crosshair: true,
              labels: {
                autoRotation: [-40]
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Hectáreas (ha)'
              }
            },
            tooltip: {
              shared: true,
              valueSuffix: ' ha'
            },
            series: [
              {
                name: 'Marítimo - acto',
                type: 'column',
                stack: 'acto',
                data: actoSeaSeries,
                color: '#abdda4'
              },
              {
                name: 'Terrestre - acto',
                type: 'column',
                stack: 'acto',
                data: actoLandSeries,
                color: '#fdae61'
              },
              {
                name: 'Marítimo - sig',
                type: 'column',
                stack: 'sig',
                data: sigSeaSeries,
                color: '#2b83ba'
              },
              {
                name: 'Terrestre - sig',
                type: 'column',
                stack: 'sig',
                data: sigLandSeries,
                color: '#d7191c'
              }
            ]
          };

          debug.info('uicnChartOptions', $scope.uicnChartOptions);
        }, function(response){
          $scope.uicnChartsSR.setErrorResponse(response);
        });











        $scope.test1SR = new ServerRequest();

        $scope.test1SR.start();
        Tests.test1().then(function(data){
          $scope.test1SR.success();

          var matrix = [];
          angular.forEach(data.series, function(value, i){
            matrix.push({
              name: value,
              data: []
            });
          });
          angular.forEach(data.data, function(value){
            angular.forEach(value, function(item, index){
              matrix[index].data.push(item);
            });
          });
          debug.info('matrix', matrix);


          /*var series1 = _.map(data, 'planeado');
          series1 = _.map(series1, function(item){
            return MathService.round(item * 100);
          });*/
          /*var series2 = _.map(data, 'ejecutado');
          series2 = _.map(series2, function(item){
            return MathService.round(item * 100);
          });*/

          $scope.test1Options = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Estado financiero'
            },
            /*plotOptions: {
              column: {
                stacking: 'normal'
              }
            },*/
            xAxis: {
              categories: matrix[0].data,
              crosshair: true,
              labels: {
                autoRotation: [-40]
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Hectáreas (ha)'
              }
            },
            tooltip: {
              shared: true,
              valueSuffix: ' ha'
            },
            series: [
              {
                name: matrix[1].name,
                type: 'column',
                //stack: 'acto',
                color: '#fdae61',
                data: matrix[1].data
              },
              {
                name: matrix[2].name,
                type: 'column',
                //stack: 'acto',
                color: '#abdda4',
                data: matrix[2].data
              }
            ]
          };
        }, function(response){
          $scope.test1SR.setErrorResponse(response);
        });
      }
    ]);
}(window.angular));
